
export default function Kalender() {
  return (
    <div className="calender_page">
      <h1>Kalender</h1>
      <iframe title='rig' 
      src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FBerlin&showTz=0&showTitle=0&src=cm9ja2ltZ2FydGVudmVyZWluQGdtYWlsLmNvbQ&color=%237CB342" 
      width="100%" 
      height="600"></iframe>
    </div>
  );
}
