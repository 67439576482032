import './articleList.scss';
import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ArticleContext } from '../../components/context';
import { useLocation } from 'react-router-dom'
import { translateText } from './../article/article'
import SoldOutIcon from './stampsold01.svg'

export default function ArticleList() {
  const location = useLocation();
  const {articleData} = useContext(ArticleContext);
  const search = location.pathname.replace('/shop/','');
  const titleClass = search === 'BAK' ? 'BAK title' : 'title';
  const current=search === 'Alles'? articleData : articleData.filter(x => x.attributes.type === search);
  current.sort((a, b) => {
    return a.attributes.order - b.attributes.order;
});
  return (
    <div className='articleListBox'> 
      <div className='content'>
        <div className="CartContainer">           
            {current.map(item => { 
              return (              
                <div key={item.id} className="Cart-Items">
                    <div className="image-box">
                      <img alt={item.attributes.name+ 'image'} src={item.attributes.bilder.data[0].attributes.formats.medium.url}/>
                      {item.attributes.soldOutLogo && <img className='soldOut' src={SoldOutIcon} alt="Sold out banner"/>}
                    </div>
                    <div className="about">
                      <h1 className={titleClass}><Link  to={"/shop/artikel/"+ item.id}>{item.attributes.name}</Link></h1>
                      <h3 className="subtitle">{translateText(item.attributes.subType1)}{translateText(item.attributes.subType2)? ` | ${translateText(item.attributes.subType2)}`:''}{translateText(item.attributes.subType3)? ` | ${translateText(item.attributes.subType3)}`:''}</h3>
                    </div>
                    <div className="prices">
                      <div className="amount">{item.attributes.preis.toFixed(2)} €</div>
                    </div>
                </div>
              )})            
            }
        </div>
      </div>
    </div>
  );
}