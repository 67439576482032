import Accordion from "../../components/Accordion";
import Map from './../../assets/images/MapRohrsheim.png'
export default function Faq() {
  return (
    <div className="faq">
      <h1>FAQ 2025</h1>

      <Accordion
        questionsAnswers={[{
          linkId: '1',
          question: 'Wann findet das Rock im Garten Festival statt?',
          answer: 'Rock im Garten Vol. 12 findet am 19.07.2025 statt.'
        },{
          linkId: '2',
          question: 'Wo findet das Rock im Garten Festival statt? ',
          answer: 'Rock im Garten findet in Inges Garten in Rohrsheim (Sachsen-Anhalt) statt. \n\n **Adresse:** Westentor 126a, 38836 Rohrhsheim'
        },{
          linkId: '3',
          question: 'Wie komme ich zu Rock im Garten?',
          answer: '**Mit dem ÖPNV:** \n\n Bitte [www.insa.de](https://www.insa.de) nutzen. Ziel „Rohrsheim - Mitteldorf“\n\n Sollte kein Bus am Wochenende fahren, können wir dich auch in Dardesheim abholen. Wende dich dazu bitte einfach an uns.\n\n \n\n **Mit dem PKW:** \n\n Aus Richtung Braunschweig über die A36 Abfahrt Wolfenbüttel -> B79 Richtung Halberstadt -> in Hessen Richtung Rohrsheim abbiegen \n\n \n\n Aus Richtung Helmstedt über die B244 -> in Dedeleben Richtung Rohrsheim abbiegen \n\n \n\n Aus Richtung Magdeburg B81 bis Halberstadt -> B79 Richtung Wolfenbüttel -> Abzweig Rohrsheim abbiegen \n\n \n\n Aus Richtung Harz/Wernigerode über die B244 -> Höhe Dardesheim auf B79 -> Abzweig Rohrsheim abbiegen \n\n \n\n',
          image: Map,
        },{
          linkId: '3',
          question: 'Wann geht es los?',
          answer: 'Einlass ist ab 12.30 Uhr | Die Bands starten ab 13:30 Uhr'
        },{
          linkId: '4',
          question: 'Gibt es ein Kinderprogramm?',
          answer: 'Ja, am Nachmittag gibt es für Kinder ein kostenfreies Kinderschminken, eine Hüpfburg, ein Bällebad und für die Eltern oder Großeltern Kaffee und Kuchen.'
        },{
          linkId: '5',
          question: 'Wo bekomme ich Tickets her?',
          answer: 'Hardtickets gibt es im VVK in unserem [Shop](http://www.rock-im-garten.com/shop/artikel/1) bis zum 14.07.2025.'
        },{
          linkId: '6',
          question: 'Was kostet ein Ticket?',
          answer: 'Das Tagesticket kostet 49,00 Euro im Vorverkauf (+ Versand und ggf, Gebühren bei externen Anbietern)\n\n \n\n Kinder bis einschließlich 15 Jahre erhalten in Begleitung eines Erziehungsberechtigten freien Eintritt. Schwerbeschädigte und im Ausweis eingetragene Begleitpersonen erhalten kostenfreien Eintritt.'
        },{
          linkId: '7',
          question: 'Gibt es Bändchen?',
          answer: 'Ja, es wird für alle Besucher auch in diesem Jahr Stoffbändchen geben!'
        },{
          linkId: '8',
          question: 'Gibt es Festival Merch?',
          answer: 'Ja, Festival T-Shirts, Hoodies und Zipper können im [Shop](http://www.rock-im-garten.com/shop/Alles) bis zum 28.06.2025 vorbestellt werden. Ausgegeben wird die Ware dann am Merchstand vor Ort am 19.07.2025. \n\n Alle Textilien sind aus 100% Bio-Baumwolle (GOTS), qualitativ hochwertig, von der Firma Continental Clothing und unter fairen und sozialen Bedingungen hergestellt.Bei Hoher Nachfrage gibt es nach der Veranstaltung noch eine Nachbestellung.'
        },{
          linkId: '9',
          question: 'Was gibt es zu Essen und zu Trinken?',
          answer: 'Zu Trinken haben wir mehrere Sorten Bier, Longdrinks, Softdrinks und Apfelsaft von Äpfeln aus dem eigenen Garten. \n\n \n\n An Speisen gibt es für alle (Fleisch, vegetarisch, vegan) etwas. Von Currywurst Pommes über Chili sin Carne bis hin zu Apfelkuchen mit Äpfeln aus dem eigenen Garten und noch einiges mehr.'
        },{
          linkId: '10',
          question: 'Wo kann ich Parken?',
          answer: 'Die Parkplätze befinden sich ganz in der Nähe des Gartens auf dem Sportplatz (2 Min. Fußweg). Folgt im Ort einfach der Ausschilderung. \n\n Direkt am Gelände gibt es leider keine Parkplätze'
        },{
          linkId: '11',
          question: 'Kann ich auch vor Ort Campen?',
          answer: 'Ja, damit ihr den Abend voll genießen und nicht früh nach Hause fahren müsst, gibt es am Sportplatz neben dem Parkplatz auch noch ein Green Camping mit Stellplätzen für Wohnmobile und Camper sowie einem Zeltplatz.'
        },{
          linkId: '12',
          question: 'Was kostet das Camping und was muss ich beachten?',
          answer: 'Wir bieten ein Green Camping für 2€ pro Person an. Damit dies auch so bleiben kann bitten wir euch einige Punkte einzuhalten: \n\n \n\n - Auf dem Park- und Campingplatz gilt ein Glasverbot \n\n - Auf Grund von erhöhter Trockenheit und Brandgefahr ist kein offenes Feuer und kein Grillen auf dem Park- und Campingplatz erlaubt. \n\n - Keine Tiere \n\n - Auf den Parkflächen dürfen keine Aufbauten, wie Zelte und Pavillons aufgestellt werden. Bitte haltet die Parkflächen sauber. \n\n - Bitte haltet den Park- und Campingplatz sauber und benutzt für Müll die dafür vorgesehenen Behältnisse \n\n - Der Park- und Campingplatz muss bis Sonntag 13.00 Uhr wieder geräumt sein \n\n'
        },{
          linkId: '13',
          question: 'Gibt es die Möglichkeit zu Duschen oder Schwimmen zu gehen?',
          answer: 'Ja, nur 3 Min. vom Gelände und 5 Min. vom Sportplatz entfernt befindet sich das Rohrsheimer Freibad. Hier könnt ihr vor und nach Rock im Garten Duschen, Schwimmen und Freibadpommes genießen. \n\n Öffnungszeiten: Samstag und Sonntag 13:00 bis 19:00 Uhr Eintritt: 2,50 Euro \n\n Alle Infos unter: [https://www.stadt-osterwieck.de/leben/freizeitangebote/freibaeder-der-einheitsgemeinde/freibad-rohrsheim/](https://www.stadt-osterwieck.de/leben/freizeitangebote/freibaeder-der-einheitsgemeinde/freibad-rohrsheim/) \n\n \n\n',
          image: Map,
        },{
          linkId: '14',
          question: 'Gibt es am Sonntagvormittag Frühstück?',
          answer: 'Ihr könnt euch am Sonntag bis 12.00 Uhr ein Frühstück to Go auf dem Veranstaltungsgelände abholen. \n\n Frühstück to Go - 4,00 Euro (2x halbe Brötchen, 1x Ei, 1x Kaffee to Go)'
        },{
          linkId: '15',
          question: 'Darf ich eigene Getränke auf das Festivalgelände mitnehmen?',
          answer: 'Das Mitbringen von Getränken auf das Festivalgelände ist nicht erlaubt. \n\n Damit ihr immer genug zu trinken habt, bieten wir das Wasser für günstige 1,00 Euro an.'
        },{
          linkId: '16',
          question: 'Darf ich mit Kindern zu Rock im Garten?',
          answer: 'Kinder dürfen Rock im Garten besuchen. Kinder bis einschließlich 15 Jahren haben freien Eintritt in Begleitung eines Erziehungsberechtigten. Auf dem gesamten Gelände gilt das Jugendschutzgesetz. Für kleinere Kinder ist ein entsprechender Gehörschutz mitzubringen. 16- bis einschließlich 17-Jährige müssen das Gelände um 24 Uhr verlassen, wenn sie ohne Begleitung eines Personensorgeberechtigten oder eines Erziehungsbeauftragten sind.'
        },{
          linkId: '17',
          question: 'Foto- und Videoaufnahmen bei Rock im Garten?',
          answer: 'Auf dem Festival werden durch die Medien und/ oder den Veranstalter Foto- und Filmaufnahmen erstellt, vervielfältigt und z.B. für aktuelle Berichterstattungen via Print, TV und/ oder Internet verbreitet. Mit dem Betreten des Festivalgeländes erklärt sich jeder Besucher damit einverstanden, das diese Aufnahmen auch die Person des Besuchers abbilden und für die Vorgenannten Zwecke entschädigungslos genutzt werden dürfen.'
        },{
          linkId: '18',
          question: 'Welche Hygieneregeln muss ich einhalten?',
          answer: 'Es gibt aktuell keine Zugangsbeschränkungen oder verpflichtende Hygienebestimmungen. Wir empfehlen trotzdem jedem sich vor dem Besuch zu testen und solltet ihr eine Maske tragen wollen ist dies auch vollkommen in Ordnung. \n\n Es stehen vor Ort ausreichend Möglichkeiten zur Händedesinfektion zur Verfügung. Solltet ihr eindeutige Krankheitssymptome haben, ist von dem Besuch der Veranstaltung abzusehen.'
        },{
          linkId: '19',
          question: 'Gibt es Handyladestationen?',
          answer: 'Ja, wir bieten auf dem Festivalgelände die Möglichkeit Handys aufzuladen.'
        },{
          linkId: '20',
          question: 'Gibt es im Ort einen Geldautomaten?',
          answer: 'Leider nein. Aber ihr könnt an allen Kassen bargeldlos mit allen gängigen Methoden bezahlen.'
        },{
          linkId: '21',
          question: 'Du bist unter 18 Jahre und möchtest Rock im Garten bis zum Schluss besuchen?',
          answer: 'Mit dem Muttizettel und einer anderen volljährigen Person kannst du die Veranstaltung, auch unter 18 Jahren, bis zum Schluss genießen. \n\n [Link zur Vorlage](https://rock-im-garten.com/Muttizettel_Rock_im_Garten_2024.pdf)'
        }]}
        defaultSelection={0}
      />
    </div>
  );
}
