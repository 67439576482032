import React, { useState, useContext } from 'react';
import Logo from '../../assets/images/RiGlogo.png'
import Menue from '../../assets/images/menue.png';
import Cart from '../../assets/images/shopping-cart.png';
import { slide as Menu } from 'react-burger-menu'
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import classNames from 'classnames';
import Oma from '../../assets/images/oma.png';
import Punker from '../../assets/images/punk.png'
import { CartContext } from '../../components/context';

import './styles.scss'
export default function Header () {
  const location = useLocation();
  const showLogo = location.pathname !== '/';
  const akustik = location.pathname.startsWith('/akustik');
  const [isOpen, setOpen] = useState(false)
  const {cartValue} = useContext(CartContext);
  let articleAmount = 0
  cartValue.map(item => { 
    return articleAmount = articleAmount + parseFloat(item.amount)
  })

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }
  const closeSideBar = () => {
    setOpen(false)
  }
  var headerClass = classNames('Header', {
    'openMenu': isOpen,
  }, {
    'openMenu': !showLogo,
  });
  return (
    <div className={headerClass}>
      {showLogo && <img className="HeaderOma" src={Oma} alt='Oma' />}  
      {showLogo && <Link to="/"><img className='Logo' src={Logo} alt="Rock im Garten Logo"/></Link>}      
      {showLogo && !akustik && <h1 className='headerTitle'>19.07.2025 Rohrsheim</h1>}
      {showLogo && akustik && <h1 className='headerTitleBig'>18.05.2024 RiG-Akustik Rohrhsheim</h1>}
       {showLogo &&<Link to="/shop/warenkorb"><img className='cartLogo' src={Cart} alt="Warenkorb"/><div className='cartCounter'>{articleAmount}</div></Link>}
       <Menu isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen} width={370} right customBurgerIcon={ <img src={Menue} alt="Menue CTA" /> }>
        <Link onClick={closeSideBar} id="home" className="menu-item" to="/">Home</Link>
        <Link onClick={closeSideBar} id="shop" className="menu-item" to="/shop/Alles">Shop</Link>
        <Link onClick={closeSideBar} id="bands" className="menu-item" to="/bands/Rantanplan">Bands - RiG 11</Link>
        <Link onClick={closeSideBar} id="akustik" className="menu-item" to="/akustik/Friedemann">RiG Akustik</Link>
        <Link onClick={closeSideBar} id="faq" className="menu-item" to="/faq">FAQ</Link>
        <Link onClick={closeSideBar} id="rules" className="menu-item" to="/rules">Inges Garten, Inges Regeln</Link>
        <Link onClick={closeSideBar} id="history" className="menu-item" to="/history/StoneColdLove">History</Link>
        <Link onClick={closeSideBar} id="verein" className="menu-item" to="/verein">Verein</Link>
        <Link onClick={closeSideBar} id="verein" className="menu-item" to="/sponsoren">Unterstützer</Link>
        <Link onClick={closeSideBar} id="impressum" className="menu-item" to="/impressum">Impressum</Link>
      </Menu>
      {showLogo && <img className="HeaderPunker" src={Punker} alt='Punker' />} 
    </div>
  );
}
  