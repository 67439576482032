import React, {useContext, useEffect, useState} from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import './shop.scss';
import { Link } from "react-router-dom";
import axios from 'axios';
import ArticleList from '../../components/articleList/articleList';
import { ArticleContext } from '../../components/context';
import Article from '../../components/article/article';
import Warenkorb from '../../components/cart/cart';
import Spinner from './../../assets/images/spinner.svg'
import { useSearchParams } from 'react-router-dom';

export default function Shop() {
  const [searchParams] = useSearchParams();
  if(parseInt(searchParams.get('mitglied'))===1){
    window.sessionStorage.setItem("mitglied", true);
  }
  if(parseInt(searchParams.get('mitglied'))===0){
    window.sessionStorage.setItem("mitglied", false);
  }
  if(parseInt(searchParams.get('abholung'))===1){
    window.sessionStorage.setItem("abholung", true);
  }
  if(parseInt(searchParams.get('abholung'))===0){
    window.sessionStorage.setItem("abholung", false);
  }
  const [loading, setLoading] = useState(true);
  const { setArticleData } = useContext(ArticleContext);
  useEffect(() => {
    const fetchArticleData = async () =>{
      setLoading(true);
      try {
        const {data: response} = await axios.get('https://strapi-3tgn.onrender.com/api/produkte?populate=*');
        setArticleData(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }

    fetchArticleData();
  }, [setArticleData]);
  return (
    <div className="shopSection">
      <ul className='navigation' role="navigation" aria-label="shopNavigation">
        <li><Link to="/shop/Alles">Alles&nbsp;</Link></li>
        <li><Link to="/shop/Tickets">Tickets&nbsp;</Link></li>
        <li><Link to="/shop/Textil">Textil&nbsp;</Link> </li>
        <li><Link to="/shop/Merch">Merch&nbsp;</Link></li>
        {
        //<li><Link className='bakStyle' to="/shop/BAK">BAK</Link></li>
        }
      </ul>
      {loading && <div className='loading'><img src={Spinner} alt="loading animation"/></div>}
      {!loading && (
          <Routes>
            <Route path=":id" element={<ArticleList/>} />
            <Route path="/warenkorb" element={<Warenkorb/>} />
            <Route path="/artikel/:id" element={<Article/>} />            
          </Routes> 
    )}
    </div>
  );
}

