import React from 'react'
import arrow from './../../assets/images/arrow.svg'
import './impressum.scss'
import { Datenschutz } from './datenschutz'
import { AGB } from './agb'
import classNames from 'classnames'

export default class Impressum extends React.Component {
  constructor() {
    super()
    this.state = {
      hideUrheber: true,
      hideDatenschutz: true,
      hideAGB: true,
      hideHaftung: true
    }
  }

  render() {
    const arrowUrheberClasses = classNames(
      'arrow',
      { active: !this.state.hideUrheber }
    )
    const arrowAGBClasses = classNames(
      'arrow',
      { active: !this.state.hideAGB }
    )
    const arrowDatenschutzClasses = classNames(
      'arrow',
      { active: !this.state.hideDatenschutz }
    )
    const arrowHaftungClasses = classNames(
      'arrow',
      { active: !this.state.hideHaftung }
    )
    return <div className="impressumContend pageWrapper">
      <h1>IMPRESSUM</h1>
      <div>
        <p>
          <strong>Dienstanbieter:</strong><br/>
          Rock im Garten e.V.<br/>
          Christian Borger<br/>
          Westentor 126a<br/>
          D-38836 Osterwieck OT Rohrsheim<br/>
          Germany
        </p>
        <p>
          <strong>Booking:</strong><br/>
          <a href="mailto:booking@rock-im-garten.com" className="autohyperlink">booking@rock-im-garten.com</a>          
        </p>
        <p>
          <strong>Gestaltung &amp; Webdesign:</strong><br/>
          Julia Wendler &amp; Sebastian Schmidt
        </p>
        <p>
          <strong>Kontakt zum Dienstanbieter und redaktionell Verantwortlichen <br/>
          sowie bei technischen oder inhaltlichen Fragen:</strong><br/>
          <a href="mailto:info@rock-im-garten.com" className="autohyperlink">info@rock-im-garten.com</a>
        </p>
        <h2
          role="button"
          tabIndex="0"
          title="öffnet Urheberrecht"
          className="clickable"
          onClick={() => this.setState({ hideUrheber: !this.state.hideUrheber })}>
          Urheberrecht
          <img src={arrow}
            className={arrowUrheberClasses} alt="Pfeil nach unten - dreht sich bei klick" />
        </h2>
        {
          this.state.hideUrheber ? '' :
            <p className="animatedContainer">
              Es gelten die Vorschriften des deutschen Urheberrechts in seiner jeweiligen Fassung.
              Alle Rechte der Urheber an den geschützten Werken, die auf der Website enthalten sind,
              bleiben vorbehalten. Ohne ausdrückliche Genehmigung durch die GEMA darf eine weitergehende
              Nutzung der Werke, über das Anhören der eingestellten Musikwerke hinaus, nicht erfolgen.
            </p>
        }
        <h2
          role="button"
          title="öffnet Haftung"
          tabIndex="0"
          className="clickable"
          onClick={() => this.setState({ hideHaftung: !this.state.hideHaftung })}>
          Haftung
          <img src={arrow} className={arrowHaftungClasses}
            alt="Pfeil nach unten - dreht sich bei klick" />
        </h2>
        {
          this.state.hideHaftung ? '' :
            <div className="animatedContainer">
              <p>
                <strong>1. Inhalt des Onlineangebotes</strong><br/>
                Der Autor selbst und die Informationszubringer sind stets bemüht, qualitativ hochwertige Informationen
                zur Verfügung zu stellen. Trotzdem übernimmt der Autor keinerlei Gewähr für die Aktualität,
                Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen
                den Autor, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder
                Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger
                Informationen verursacht wurden sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein
                nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
                freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten
                oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen
                oder die Veröffentlichung zeitweise oder endgültig einzustellen.
              </p>
              <p>
                <strong>2. Verweise und Links</strong><br/>
                Sofern auf Verweisziele („Links“) direkt oder indirekt verwiesen wird, die außerhalb des
                Verantwortungsbereiches des Autors liegen, haftet dieser nur dann, wenn er von den Inhalten Kenntnis
                hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu
                verhindern. für darüber hinausgehende Inhalte und insbesondere für Schaden, die aus der Nutzung oder
                Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter dieser Seiten,
                nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Diese
                Einschränkung gilt gleichermaßen auch für Fremdeinträge in vom Autor eingerichteten Gästebüchern,
                Diskussionsforen und Mailinglisten.
              </p>
              <p>
                <strong>3. Urheberrecht</strong><br/>
                Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Videos,
                Sounds und Texte zu beachten, von ihm selbst erstellte Grafiken, Videos, Sounds und Texte zu nutzen
                oder auf lizenzfreie Grafiken, Sounds und Texte zurückzugreifen. Sollte sich auf den jeweiligen Seiten
                dennoch eine ungekennzeichnete, aber durch fremdes Copyright geschützte Grafik, ein Sound oder Text
                befinden, so konnte das Copyright vom Autor nicht festgestellt werden. Im Falle einer solchen
                unbeabsichtigten Copyrightverletzung wird der Autor das entsprechende Objekt nach Benachrichtigung
                aus seiner Publikation entfernen bzw. mit dem entsprechenden Copyright kenntlich machen.
              </p>
              <p>
                <strong>4. Rechtswirksamkeit dieses Haftungsausschlusses</strong><br/>
                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese
                Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
                nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes
                in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
              </p>
            </div>
        }
        <h2
          role="button"
          title="öffnet Datenschutz"
          tabIndex="0"
          className="clickable"
          onClick={() => this.setState({ hideDatenschutz: !this.state.hideDatenschutz })}>
          Datenschutz
          <img src={arrow}
            className={arrowDatenschutzClasses} alt="Pfeil nach unten - dreht sich bei klick" />
        </h2>
         {
          this.state.hideDatenschutz ? '' :
            <div className="animatedContainer">
              <Datenschutz/>
            </div>
        }
        <h2
          role="button"
          title="öffnet AGB"
          tabIndex="0"
          className="clickable"
          onClick={() => this.setState({ hideAGB: !this.state.hideAGB })}>
          AGB
          <img src={arrow}
            className={arrowAGBClasses} alt="Pfeil nach unten - dreht sich bei klick" />
        </h2>
         {
          this.state.hideAGB ? '' :
            <div className="animatedContainer">
              <AGB/>
            </div>
        }
      </div>
    </div>
  }
}