import Oma from '../../assets/images/oma.png';
import Punker from '../../assets/images/punk.png'
import { useLocation } from 'react-router-dom'
import './styles.scss'
import Facebook from '../../assets/images/facebook-logo2.png'
import Insta from '../../assets/images/instagram-logo2.png'
import Youtube from './../../assets/images/youtube-logo2.png'

export default function Footer () {
  const location = useLocation();
  if(location.pathname === '/'){
      return <></>
  }
    return(
        <div className="Footer">
            <div className="content">
                <img className="Oma" src={Oma} alt='Oma' />
                <div className='links'>
                    <a href='https://www.facebook.com/RockimGartenev' target='_blank' title='zum Facebook-Account' rel="noreferrer"><img className="icon" src={Facebook} alt="Rock im Garten Facebook Logo" /></a>
                    <a href='https://www.instagram.com/rock_im_garten' target='_blank' title='zum Instagram-Account' rel="noreferrer"><img className="icon" src={Insta} alt="Rock im Garten Instagram Logo" /></a>
                    <a href='https://youtube.com/@rockimgartene.v.4649' target='_blank' title='zum Youtube-Account' rel="noreferrer"><img className="icon" src={Youtube} alt="Rock im Garten Youtube Logo" /></a>
                </div>
                <img className="Punker" src={Punker} alt='Punker' />
            </div>
        </div>
    )
}