import './xmas.scss'
import { Link } from "react-router-dom";

export default function Verein() {
  return (
    <div className='XmasContainer'>
      <h1>Rock im Garten Hüttenzauber</h1>
      <p>Wir machen keinen Winterschlaf - ganz im Gegenteil!<br/><br/>
      Wir haben für euch eine Weihnachtsmarkthütte organisiert an der wir vom 02.-04. Dezember 2022<br/>
      im Rathaus-Innenhof in Wernigerode nicht nur zusammen Apfelpunsch von Äpfeln aus dem eigenen Garten<br/>
      trinken können, ihr selbstgemachte Marmeladen und Plätzchen als Weihnachtsgeschenk für Eltern und Großeltern<br/>
      bekommt sondern auch musikalisch noch etwas geboten wird! </p>
      <p>Beim Rock im Garten - Christmas-Rock erwarten euch folgende Künstler aus der Region:<br/><br/>
      <Link data-weight="2" to="/bands/DieLetzteBandDerWelt">Freitag, 02.12.2022 - ab 18:00 Uhr - Die letzte Band der Welt</Link> <br/>
      <Link data-weight="2" to="/bands/FrauSchroeder">Samstag, 03.12.2022 - ab 16:00 Uhr - Frau Schröder</Link> <br/>
      <Link data-weight="2" to="/bands/PicturesOfSoul">Samstag, 03.12.2022 - ab 18:00 Uhr - Pictures of Soul</Link> <br/>
      <Link data-weight="2" to="/bands/LarsBegerow">Sonntag, 04.12.2022 - ab 17:00 Uhr - Lars Begerow</Link> </p>
    </div>
  );
}
