import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({ questionsAnswers, defaultSelection = 1 }) => {
  const [activeIndex, setActiveIndex] = useState(
    defaultSelection < 0 ? 0 : defaultSelection
  );

  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    return (
      <AccordionItem
        key={index}
        showDescription={index === activeIndex}
        fontWeightBold={index === activeIndex}
        ariaExpanded={index === activeIndex}
        item={item}
        index={index}
        onClick={() => {
          setActiveIndex(index);
        }}
      />
    );
  });

  return renderedQuestionsAnswers;
};

export default Accordion;
