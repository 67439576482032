import Respect from './../../assets/images/respekt.svg'
import Help from './../../assets/images/hilfe.svg'
import Consumption from './../../assets/images/kosum.svg'
import Moshpit from './../../assets/images/pogen.svg'
import Clothes from './../../assets/images/kleidung.svg'
import Tolerance from './../../assets/images/toleranz.svg'
import Forbidden from './../../assets/images/verboten.svg'
import Environment from './../../assets/images/umwelt.svg'
import Water from './../../assets/images/wasser.svg'

export default function Rules() {
  return (
          <div class="SponsorenContainer">
                <h1>Inges Garten, Inges Regeln</h1>
                <p class="sponsorenText">Respektiere die Regeln und den Garten von Inge.</p>
                <figure class="SponsorenList">
                <img class="sponsorImage" src={Respect} alt="Respekt" />
                <figcaption>
                    <h2>Zusammen sicher und respektvoll</h2>
                    <p>Schaffe ein sicheres und respektvolles Umfeld für alle. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Help} alt="Hilfe" />
                <figcaption>
                    <h2>Hilfe bei Problemen</h2>
                    <p>Bei Unwohlsein oder Ärger wende dich an die Security oder Crew.</p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Consumption} alt="Konsum" />
                <figcaption>
                    <h2>Verantwortungsvoller Konsum</h2>
                    <p>Beachte dein Limit und störe keine anderen. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Moshpit} alt="Pogen" />
                <figcaption>
                    <h2>Rücksicht beim Pogen</h2>
                    <p> Achte auf kleinere und schwächere Personen und biete Hilfe an, wenn nötig. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Clothes} alt="Kleidung" />
                <figcaption>
                    <h2>Kleidung und Hitze</h2>
                    <p> Bitte lasse aus Respekt deine Oberteile an, auch wenn es warm ist. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Tolerance} alt="Tolerance" />
                <figcaption>
                    <h2>Null Toleranz für Diskriminierung und Gewalt</h2>
                    <p>Wir dulden keine Formen von Extremismus, Sexismus, Queerfeindlichkeit, Rassismus, rechten Parolen oder Gewalt.</p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Forbidden} alt="Verboten" />
                <figcaption>
                    <h2>Verbotene Gegenstände</h2>
                    <p>Keine Waffen, Glasbehälter, Plastikflaschen oder Feuerwerkskörper auf dem Gelände. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Environment} alt="Umwelt" />
                <figcaption>
                    <h2>Umweltschutz</h2>
                    <p> Entsorge Müll in die dafür vorgesehenen Behälter und achte auf die Natur, dies gilt besonders für Kippenstummel. </p>
                </figcaption>
                           </figure>
                           <figure class="SponsorenList">
                <img class="sponsorImage" src={Water} alt="Wasser" />
                <figcaption>
                    <h2>Kostenloses Trinkwasser</h2>
                    <p>Nutze das kostenlose Trinkwasser, das zur Verfügung steht.</p>
                </figcaption>
                           </figure>
            </div>
  );
}
