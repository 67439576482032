import {
  Routes,
  Route
} from "react-router-dom";
import './bands.scss';
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Band from "../../components/band/band";
import Spinner from './../../assets/images/spinner.svg'
import { HashLink as Link } from 'react-router-hash-link';
import FlyerAkustik from '../../assets/images/RiGAkustik24Flyer.png'
export default function Bands({history, akustik}) {  
  const path = history ? '/history' : akustik ? '/akustik' : '/bands';
  const [bandData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () =>{
      setLoading(true);
      try {
        const {data: response} = await axios.get('https://strapi-3tgn.onrender.com/api/bands-rig?populate=*');
        setData(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }
    fetchData();
  }, [setData]);

  function compare( a, b ) {
    if ( a.attributes.sort < b.attributes.sort ){
      return -1;
    }
    if ( a.attributes.sort > b.attributes.sort ){
      return 1;
    }
    return 0;
  }
  function filter_dates(band) {
    if(akustik){
      return band.attributes.teilgenommen.find(x => {
        return x.teilgenommen === "AKKUSTIK2024";
      });
    }
    return band.attributes.imAktuellenLinup;
  }
  var filteredBandData = !history ? bandData.filter(filter_dates): bandData;
  const navigationClass = akustik ? 'navigation bigHeader' : 'navigation'
  return (
    <div className="bandsSection">
      <div className={navigationClass}>
        <ul className="cloud" role="navigation" aria-label="Bandcloud">
        {!loading && filteredBandData.sort( compare ).map((bandRaw, i, row )=> {
          const band = bandRaw.attributes
          const bandURL = path + band.rigUrl+'#bandbox'; 
          if((!history && band.imAktuellenLinup) || (history && !band.imAktuellenLinup) || akustik){
            if (i + 1 === row.length) {
              return (<li><Link data-weight={band.fontsize} to={bandURL}>{band.name}</Link></li>)
            } 
            return (<li><Link data-weight={band.fontsize} to={bandURL}>{band.name} - </Link></li>)
          }
          return <></>
        })}
        </ul>
      </div>
      <div className="infos">
        {akustik && <img className="flyerAkustik" src={FlyerAkustik} alt="Rock im Garten Akustik - Flyer" />}
      {loading && <div className='loading'><img src={Spinner} alt="loading animation"/></div>}
      </div>    
        {akustik && <div id='bandbox'></div>}
        <Routes>
          {!loading && <Route path=":id" element={<Band path={path} bandData={bandData}/>} />}
        </Routes>
    </div>
  );
}