import Satzung from './../../assets/2020-01-17_Satzung.pdf'
import Antrag from './../../assets/Mitgliedsantrag.pdf'
import './verein.scss'

export default function Verein() {
  return (
    <div className='VereinContainer'>
      <h1>Kurz zu uns dem Rock im Garten e.V.</h1>
      <p>Rock im Garten ist einmalig: Pogo, Punkrock, Pflaumenbaum!  <br/>
      <br/>
      Im Garten von Inge zimmern wir zwischen Blumenbeeten eine Bühne, lassen die Apfelbäume wackeln, wenn wir die Anlage aufdrehen und Punk-, Rock-, Ska- und Hardcore-Bands spielen. Wir bieten Newcomer und bekannten Bands eine Bühne auf der sie gemeinsam stehen können. <br/>
      <br/>
      Sonst sagen sich in Rohrsheim (Sachsen-Anhalt) eher Fuchs und Hase gute Nacht - aber nicht an diesem Wochenende! Als gemeinnütziger Verein holen wir Kultur (ja, das ist Punkrock auch!) aufs Dorf. <br/>
      <br/>
      PS: Wir haben nicht nur gute Musik, sondern auch selbstgemachten Apfelkuchen. <br/>
</p>
      <h2>Mitglied werden:</h2>
      <a href={Antrag} download="Mitgliedsantrag-Rock-im-Garten.pdf" title="PDF für den Mitgliedsantrag">-> PDF für den Mitgliedsantrag</a>
      <h2>Satzung des Vereins:</h2>
      <a href={Satzung} download="Vereinssatzung-Rock-im-Garten.pdf" title="zur Satzung">-> zur Satzung</a>
      <h2>Spendenkonto:</h2>
      <p>
        IBAN: DE60 8105 2000 0901 0703 19<br/>
        BIC: NOLADE21HRZ
      </p>
      <form action="https://www.paypal.com/donate" method="post" target="_blank">
      <input type="hidden" name="hosted_button_id" value="ZBXSB85D7Q3FJ" />
      <input type="image" src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Spenden mit dem PayPal-Button" />
      <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>
  );
}
